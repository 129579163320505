import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={19} height={23} {...props} className="side-icon">
      <g
        fill="none"
        stroke="#d5c8f4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-name="Icons/Layout"
      >
        <path d="M.5.5h7.125v11H.5z" />
        <path
          data-name="Rectangle-path"
          d="M.5 15.625h7.125v5.5H.5zM11.187.5h7.125v6.875h-7.125zM11.187 11.5h7.125v9.625h-7.125z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
