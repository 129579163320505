import React, { useContext } from "react";
import Reducer from "./reducer";
const MaterialContext = React.createContext();

function MaterialProvider({ children }) {
  const [state, dispatch] = Reducer();

  return (
    <MaterialContext.Provider value={{ state, dispatch }}>
      <div>{children}</div>
    </MaterialContext.Provider>
  );
}

function useMaterial() {
  const context = useContext(MaterialContext);
  if (context === undefined) {
    throw new Error("useMaterial must be used within a MaterialProvider");
  }
  return [context.state, context.dispatch];
}

export { MaterialProvider, useMaterial };
