import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useProfile } from "context/ProfileContext";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Dashboard from "components/Dashboard/Base";
import PublicDashboard from "components/UI/PublicBase";
import { MaterialProvider } from "./context/MaterialContext";
const Login = lazy(() => import("./pages/Login/index"));
const LoginAdmin = lazy(() => import("./pages/Login/admin"));
const PatientList = lazy(() => import("./pages/Patient/List"));
const FollowList = lazy(() => import("./pages/Patient/Follow"));
const AdvancedSearch = lazy(() => import("./pages/Patient/AdvancedSearch"));
const PatientDetail = lazy(() => import("./pages/Patient/Detail"));
const DetailedInformation = lazy(() =>
  import("./pages/Patient/DetailedInformation")
);
const PatientRegistration = lazy(() => import("./pages/Patient/Add"));
const PatientMaterials = lazy(() => import("./pages/Patient/Materials"));
const PublicPatientMaterials = lazy(() =>
  import("./pages/Patient/PublicMaterials")
);
const QuestionareQuestionDetail = lazy(() =>
  import("./pages/Patient/DetailQuestion")
);
const QuestionareResult = lazy(() =>
  import("./pages/Patient/QuestionareResult")
);
const FillQuestionare = lazy(() => import("./pages/Plan/FillQuestionare"));
const OldAddMedicine = lazy(() => import("./pages/Medicine/OldAdd"));
const AddMedicine = lazy(() => import("./pages/Medicine/Add"));
const EditMedicine = lazy(() => import("./pages/Medicine/Edit"));
const MedicineList = lazy(() => import("./pages/Settings/MedicineList"));
const DetailMedicine = lazy(() => import("./pages/Settings/DetailMedicine"));
const MedicineMaterials = lazy(() => import("./pages/Medicine/Materials"));

const AddPlan = lazy(() => import("./pages/Plan/Add"));
const ViewPlan = lazy(() => import("./pages/Plan/View"));
const OldAddPatientQuestionare = lazy(() =>
  import("./pages/Plan/AddQuestionare.old")
);
const AddPatientQuestionare = lazy(() => import("./pages/Plan/AddQuestionare"));
const NotFound = lazy(() => import("./pages/NotFound"));
// Settings
const Settings = lazy(() => import("./pages/Settings/Password"));
const AdminSettings = lazy(() => import("./pages/Settings/Admin"));
const AddGroupAndFacility = lazy(() =>
  import("./pages/Settings/AddGroupAndFacility")
);
const GroupAndFacility = lazy(() =>
  import("./pages/Settings/GroupAndFacility")
);
const AddPharmacist = lazy(() => import("./pages/Settings/AddPharmacist"));
const DetailPharmacist = lazy(() =>
  import("./pages/Settings/DetailPharmacist")
);
const PharmacistList = lazy(() => import("./pages/Settings/PharmacistList"));
const AdminAddMedicine = lazy(() => import("./pages/Settings/AddMedicine"));
const AddMedicineReference = lazy(() =>
  import("./pages/Settings/AddMedicineReference")
);
const EditMedicineReference = lazy(() =>
  import("./pages/Settings/EditMedicineReference")
);
const MedicineReferenceList = lazy(() =>
  import("./pages/Settings/MedicineReferenceList")
);
const AddMonitoringPlan = lazy(() =>
  import("./pages/Settings/AddMonitoringPlan")
);
const EditMonitoringPlan = lazy(() =>
  import("./pages/Settings/EditMonitoringPlan")
);
const MonitoringPlanList = lazy(() =>
  import("./pages/Settings/MonitoringPlanList")
);

const progress = NProgress.configure({ showSpinner: false });
const Loading = () => {
  useEffect(() => {
    progress.start();
    return () => {
      NProgress.done();
    };
  });

  return null;
};

const AppRoute = () => {
  const [profile] = useProfile();
  const isAuth = window.localStorage.getItem("access_token");

  const UnAuthedRoutes = () => (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/loginadmin">
            <LoginAdmin />
          </Route>
          <Route exact path="/patient/reference/:insurance_number">
            <PublicDashboard>
              <PublicPatientMaterials />
            </PublicDashboard>
          </Route>
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    </Router>
  );

  return !isAuth ? (
    <UnAuthedRoutes />
  ) : (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* admin routes */}
          <Route path="/404" component={NotFound} />
          <Redirect exact from="/loginadmin" to="/" />
          <Redirect exact from="/login" to="/" />
          <Route exact path={["/settings/*"]}>
            <Dashboard>
              <Suspense fallback={<Loading />}>
                {profile.role && !profile.role.includes("admin") && (
                  <Redirect from="*" to="/404" />
                )}
                <Switch>
                  <Route exact path="/settings/admin">
                    <AdminSettings />
                  </Route>
                  <Route exact path="/settings/admin/facility">
                    <GroupAndFacility />
                  </Route>
                  <Route exact path="/settings/admin/facility/add">
                    <AddGroupAndFacility />
                  </Route>
                  <Route exact path="/settings/admin/pharmacist">
                    <PharmacistList />
                  </Route>
                  <Route exact path="/settings/admin/pharmacist/add">
                    <AddPharmacist />
                  </Route>
                  <Route path="/settings/admin/pharmacist/:id">
                    <DetailPharmacist />
                  </Route>
                  <Route exact path="/settings/admin/medicine">
                    <MedicineList />
                  </Route>
                  <Route exact path="/settings/admin/medicine/add">
                    <AdminAddMedicine />
                  </Route>
                  <Route exact path="/settings/admin/medicine/:id">
                    <DetailMedicine />
                  </Route>
                  <Route exact path="/settings/admin/medicine-reference">
                    <MedicineReferenceList />
                  </Route>
                  <Route
                    exact
                    path="/settings/admin/medicine-reference/:medicine_id"
                  >
                    <MedicineReferenceList />
                  </Route>
                  <Route
                    exact
                    path="/settings/admin/medicine-reference/add/:mid"
                  >
                    <AddMedicineReference />
                  </Route>
                  <Route
                    exact
                    path="/settings/admin/medicine-reference/edit/:id"
                  >
                    <EditMedicineReference />
                  </Route>
                  <Route exact path="/settings/admin/monitoring-plan">
                    <MonitoringPlanList />
                  </Route>
                  <Route exact path="/settings/admin/monitoring-plan/add">
                    <AddMonitoringPlan />
                  </Route>
                  <Route exact path="/settings/admin/monitoring-plan/:mid/add">
                    <AddMonitoringPlan />
                  </Route>
                  <Route exact path="/settings/admin/monitoring-plan/:mid">
                    <MonitoringPlanList />
                  </Route>
                  <Route
                    exact
                    path="/settings/admin/monitoring-plan/:mplanid/edit"
                  >
                    <EditMonitoringPlan />
                  </Route>
                  <Redirect from="*" to="/404" />
                </Switch>
              </Suspense>
            </Dashboard>
          </Route>
          {/* pharmacist routes */}
          <Route exact path={["/patient/*", "/register/*", "/medicine/*"]}>
            <Dashboard>
              <Suspense fallback={<Loading />}>
                {profile.role && !profile.role.includes("pharmacist") && (
                  <Redirect from="*" to="/404" />
                )}
                <Switch>
                  <Route exact path="/patient/add">
                    <PatientRegistration />
                  </Route>
                  <Route exact path="/patient/medicine/add/">
                    <OldAddMedicine />
                  </Route>
                  <Route exact path="/patient/medicine/edit/:ptid">
                    <EditMedicine />
                  </Route>
                  <Route exact path="/patient/edit/:insurance_number">
                    <PatientRegistration />
                  </Route>
                  <Route exact path="/patient/detail/:insurance_number">
                    <PatientDetail />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/information/:key"
                  >
                    <DetailedInformation />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/plan/regis"
                  >
                    <AddPlan />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/plan/regis/:med_id"
                  >
                    <AddPlan />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/plan/view"
                  >
                    <ViewPlan />
                  </Route>
                  <Route exact path="/patient/questionare/fill/:plan_day_id">
                    <FillQuestionare />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/medicine/add"
                  >
                    <AddMedicine />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/medicine/edit"
                  >
                    <EditMedicine />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/reference"
                  >
                    <MaterialProvider>
                      <PatientMaterials />
                    </MaterialProvider>
                  </Route>

                  <Route exact path="/patient/detail/:id/materials">
                    <MedicineMaterials />
                  </Route>
                  <Route exact path="/medicine/reference/:medicine_id">
                    <MaterialProvider>
                      <MedicineMaterials />
                    </MaterialProvider>
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/questionare/regis"
                  >
                    <OldAddPatientQuestionare />
                  </Route>
                  <Route exact path="/patient/:plan_id/questionare/regis">
                    <AddPatientQuestionare />
                  </Route>
                  <Route
                    exact
                    path="/patient/detail/:insurance_number/questionare"
                  >
                    <QuestionareResult />
                  </Route>
                  <Route exact path="/patient/detail/question/:day_id">
                    <QuestionareQuestionDetail />
                  </Route>
                  <Route exact path="/patient/reference/:insurance_number">
                    <PublicPatientMaterials />
                  </Route>
                  <Redirect from="*" to="/404" />
                </Switch>
              </Suspense>
            </Dashboard>
          </Route>
          <Route
            exact
            path={["/", "/patients", "/settings", "/search/*", "/search"]}
          >
            <Dashboard>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route exact path="/settings">
                    <Settings />
                  </Route>
                  <Route exact path="/">
                    {isAuth &&
                      profile.role &&
                      (profile.role.includes("admin") ? (
                        <AdminSettings />
                      ) : (
                        <FollowList />
                      ))}
                  </Route>
                  <Route exact path="/patients">
                    <PatientList />
                  </Route>
                  <Route exact path="/search">
                    <AdvancedSearch />
                  </Route>
                  <Route exact path="/search/:search">
                    <AdvancedSearch />
                  </Route>
                  <Redirect from="*" to="/404" />
                </Switch>
              </Suspense>
            </Dashboard>
          </Route>
          <Redirect from="*" to="/404" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoute;
