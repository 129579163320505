import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={19} height={23} {...props} className="side-icon fill">
      <path
        d="M27.352 11.8l-2.866-.22a10.671 10.671 0 00-1.359-3.28L25 6.117a.7.7 0 00-.037-.954l-2.126-2.126A.7.7 0 0021.883 3L19.7 4.873a10.72 10.72 0 00-3.274-1.359L16.2.648A.7.7 0 0015.5 0h-3a.7.7 0 00-.7.648l-.22 2.866A10.671 10.671 0 008.3 4.873L6.117 3a.7.7 0 00-.953.036L3.037 5.164A.7.7 0 003 6.118L4.873 8.3a10.72 10.72 0 00-1.359 3.274l-2.866.22A.7.7 0 000 12.5v3a.7.7 0 00.648.7l2.866.22a10.671 10.671 0 001.359 3.28L3 21.882a.7.7 0 00.037.954l2.126 2.126a.7.7 0 00.954.038L8.3 23.126a10.7 10.7 0 003.274 1.359l.22 2.866a.7.7 0 00.7.649H15.5a.7.7 0 00.7-.648l.22-2.866a10.671 10.671 0 003.28-1.359L21.883 25a.7.7 0 00.954-.037l2.126-2.126a.7.7 0 00.037-.954L23.127 19.7a10.72 10.72 0 001.359-3.274l2.866-.22A.7.7 0 0028 15.5v-3a.7.7 0 00-.648-.7zM14 20a6 6 0 116-6 6 6 0 01-6 6z"
        fill="#d5c8f4"
      />
    </svg>
  );
}

export default SvgComponent;
