import { useReducer } from "react";
import { actionTypes } from "./actions";

const initState = {
  data: {},
  deleted: {},
  lock: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_PAYLOAD: {
      return { ...initState };
    }
    case actionTypes.SET_DATA: {
      return { ...state, data: action.data };
    }
    case actionTypes.SET_DELETED: {
      return { ...state, deleted: action.data };
    }
    case actionTypes.TOGGLE_LOCK: {
      return { ...state, lock: !state.lock };
    }
    case actionTypes.SET_LOCK: {
      return { ...state, lock: true };
    }
    case actionTypes.UNSET_LOCK: {
      return { ...state, lock: false };
    }
    default:
      return state;
  }
};

const Reducer = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  return [state, dispatch];
};

export default Reducer;
