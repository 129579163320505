import React, { useEffect, useState } from "react";
import "antd/dist/antd.less";
import "./App.less";
// import { Modal } from "antd";
import Routes from "./Routes";
import fetcher from "utils/Fetcher";
import { ProfileProvider } from "./context/ProfileContext";

function App() {
  const token = window.localStorage.getItem("access_token");
  const [user, setUser] = useState(null);
  const getUser = async () => {
    try {
      const response = await fetcher.post(`/user`);
      setUser(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token]);

  return (
    <div className="App">
      <ProfileProvider profile={user}>
        <Routes />
      </ProfileProvider>
    </div>
  );
}

export default App;
