export const actionTypes = {
  RESET_PAYLOAD: "RESET_PAYLOAD",
  SET_DATA: "SET_DATA",
  SET_DELETED: "SET_DELETED",
  TOGGLE_LOCK: "TOGGLE_LOCK",
  SET_LOCK: "SET_LOCK",
  UNSET_LOCK: "UNSET_LOCK",
};

// ACTIONS
export const RESET_PAYLOAD = () => {
  return {
    type: actionTypes.SET_PERSONALDATA,
  };
};

export const TOGGLE_LOCK = () => {
  return {
    type: actionTypes.TOGGLE_LOCK,
  };
};

export const SET_LOCK = () => {
  return {
    type: actionTypes.SET_LOCK,
  };
};

export const UNSET_LOCK = () => {
  return {
    type: actionTypes.UNSET_LOCK,
  };
};

export const SET_DATA = (payload) => {
  return { type: actionTypes.SET_DATA, data: payload };
};

export const SET_DELETED = (payload) => {
  return { type: actionTypes.SET_DELETED, data: payload };
};
