import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={19} height={23} {...props} className="side-icon">
      <g
        fill="none"
        stroke="#d5c8f4"
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth={2}
        data-name="Icons/Single-copy"
      >
        <path d="M1 3.933h16.133V23H1zM4.667 9.067h8.8" />
        <path
          data-name="Shape"
          d="M4.667 13.467h8.8M4.667 17.867H7.6M3.2 1h16.867v19.8"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
