import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={19} height={23} {...props} className="side-icon fill">
      <g
        fill="#d5c8f4"
        stroke="#d5c8f4"
        strokeLinecap="square"
        strokeMiterlimit={10}
        transform="translate(.5 .567)"
        data-name="Icons/Bank"
      >
        <path d="M11 0L0 5.867V8.8h22V5.867z" />
        <path
          data-name="Shape"
          d="M2.2 16.867V11M8.067 16.867V11M13.933 16.867V11M19.8 16.867V11"
        />
        <circle
          cx={1.467}
          cy={1.467}
          r={1.467}
          transform="translate(9.533 3.667)"
        />
        <path d="M0 19.067h22V22H0z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
