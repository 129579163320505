import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={19} height={23} {...props} className="side-icon">
      <g
        data-name="Icons/Ruler-Pencil"
        fill="none"
        stroke="#f3f1fb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      >
        <path d="M14.5 4.31h6" />
        <path
          data-name="Shape"
          d="M20.5 16.69l-3 3.81-3-3.81V.5h6zM.5 5.262h3M.5 9.072h2M.5 12.881h3M.5 16.691h2"
        />
        <path d="M.5.5h8v20.952h-8z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
