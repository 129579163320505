import React, { useContext, useEffect } from "react";
import Reducer from "./reducer";
const UserContext = React.createContext();

function ProfileProvider({ children, profile }) {
  const [state, dispatch] = Reducer();
  useEffect(() => {
    if (profile) dispatch({ type: "setUser", val: profile });
  }, [profile, dispatch]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <div>{children}</div>
    </UserContext.Provider>
  );
}

function useProfile() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return [context.state, context.dispatch];
}

export { ProfileProvider, useProfile };
