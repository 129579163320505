import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Divider, Row, Col } from "antd";
import HomeIcon from "../Icons/home";

import "./index.css";

const { Content, Sider } = Layout;

const Dashboard = ({ children }) => {
  return (
    <Layout>
      <Layout>
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            left: 0,
          }}
          width={250}
        >
          <div className="side-top-container">
            <span className="side-title">MediFrame for Medical</span>
            <Divider className="divider-light" />
            <div>
              <Row align="middle" gutter={16}>
                <Col span={24}>
                  <span>ログインしてください</span>
                </Col>
              </Row>
            </div>
            <Divider className="divider-light" />
          </div>
          <Menu mode="inline">
            <Menu.Item
              key="/logout"
              icon={<HomeIcon width={20} height={20} viewBox="0 0 28 28" />}
            >
              <Link to="/login">Log In</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
