import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "setUser": {
      return { ...state, ...action.val };
    }

    default:
      return state;
  }
};

const initState = {};

const Reducer = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  return [state, dispatch];
};

export default Reducer;
