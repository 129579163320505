import axios from "axios";
import { Modal } from "antd";
import { BASE_API_URL } from "./../config";

const instance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 60000,
  withCredentials: true,
});

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

instance.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${window.localStorage.getItem(
      "access_token"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (r) => {
    let token = getCookie("access_token");
    if (token) {
      window.localStorage.setItem("access_token", token);
    }
    return r;
  },
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        window.localStorage.removeItem("access_token");
        Modal.error({
          title: "セッション切れです",
          onOk: () => {
            window.location.reload();
          },
        });
        throw new axios.Cancel();
      }
    }

    throw err;
  }
);

export default instance;
