import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Divider,
  Input,
  Avatar,
  Row,
  Col,
  Modal,
  Button,
  Upload,
} from "antd";
import fetcher from "utils/Fetcher";
import { useProfile } from "context/ProfileContext";
import ImgCrop from "antd-img-crop";
// import BookIcon from "../Icons/book";
import BlockIcon from "../Icons/block";
import CopyIcon from "../Icons/copy";
import EditIcon from "../Icons/edit";
import HomeIcon from "../Icons/home";
import SettingsIcon from "../Icons/settings";
import { MenuOutlined } from "@ant-design/icons";

import "./index.css";

const { Content, Sider, Header } = Layout;

const Dashboard = ({ children }) => {
  const location = useLocation();
  // const history = useHistory();
  const [profile] = useProfile();
  const [modalState, setModal] = useState({
    open: false,
  });
  const [state, setState] = useState({
    file: null,
    uploadingPhoto: false,
    temp_photo: ``,
    key: "/home",
    collapsed: false,
    mob: false,
  });

  const openModal = () => {
    setModal({ ...modalState, open: true });
  };

  const closeModal = () => {
    setModal({ ...modalState, open: false });
  };

  const removePhoto = async () => {
    try {
      const response = await fetcher.delete("/photo");
      Modal.success({
        title: response.data.msg,
        onOk: () => {
          window.location.reload();
          // closeModal();
        },
      });
    } catch (e) {
      Modal.error({
        title: e.response.data.msg,
        onOk: () => {
          closeModal();
        },
      });
    }
    closeModal();
  };

  const handleUpload = async () => {
    const { file } = state;
    const formData = new FormData();
    formData.append("photo", file);

    setState({ ...state, uploadingPhoto: true });
    try {
      const response = await fetcher({
        method: "post",
        url: "/photo",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      });

      setState({ ...state, uploadingPhoto: false });
      Modal.success({
        title: response.data.msg,
        onOk: () => {
          window.location.reload();
          // closeModal();
        },
      });
    } catch (e) {
      console.log(e);
      setState({ ...state, uploadingPhoto: false });
      Modal.error({
        title: e.response.data.msg,
        onOk: () => {
          closeModal();
        },
      });
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  useEffect(() => {
    let key = location.pathname;
    if (key === "/" || key === "/patients") {
      key = "/home";
      if (profile.role && profile.role.includes("admin")) key = "/admin";
    } else if (key.includes("add")) {
      key = "/register";
    } else if (key.includes("admin")) {
      key = "/admin";
    }
    setState((st) => ({ ...st, key: key }));
  }, [location.pathname, profile.role]);

  useEffect(() => {
    let mob = window.innerWidth <= 800 || window.innerHeight <= 600;
    if (mob) {
      setState((st) => ({ ...st, collapsed: true, mob: true }));
    } else {
      setState((st) => ({ ...st, collapsed: false, mob: false }));
    }
  }, []);
  return (
    <Layout>
      {state.mob && (
        <Header className="header" color="primary">
          <Button
            icon={
              <MenuOutlined
                onClick={() => {
                  setState({ ...state, collapsed: !state.collapsed });
                }}
              />
            }
          />
        </Header>
      )}
      <Layout>
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            left: 0,
          }}
          collapsed={state.collapsed}
          collapsedWidth={0}
          width={250}
        >
          <div className="side-top-container">
            <span className="side-title">MediFrame for Medical</span>
            <Divider className="divider-light" />
            <div>
              <Row align="middle" gutter={16}>
                <Col span={10}>
                  {profile.id && (
                    <Avatar
                      size={64}
                      src={profile.photo}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        openModal();
                      }}
                    />
                  )}
                </Col>
                <Col span={14} className="left-text">
                  <span>{profile.full_name}</span>
                </Col>
              </Row>
            </div>
            <Divider className="divider-light" />
          </div>
          {profile.role && (
            <Menu
              mode="inline"
              selectedKeys={state.key}
              defaultSelectedKeys={state.key}
            >
              {profile.role.includes("pharmacist") && (
                <>
                  <Menu.Item
                    key="/home"
                    icon={
                      <CopyIcon width={20} height={20} viewBox="0 0 25 25" />
                    }
                  >
                    <Link to="/">フォロー患者一覧</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/register"
                    icon={
                      <EditIcon width={20} height={20} viewBox="0 0 25 25" />
                    }
                  >
                    <Link to="/patient/add">患者登録</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/search"
                    icon={
                      <BlockIcon width={20} height={20} viewBox="0 0 25 25" />
                    }
                  >
                    <Link to="/search">患者検索</Link>
                  </Menu.Item>
                </>
              )}
              {profile.role && profile.role.includes("admin") && (
                <Menu.Item
                  key="/admin"
                  icon={
                    <BlockIcon width={20} height={20} viewBox="0 0 25 25" />
                  }
                >
                  <Link to="/settings/admin">システムメンテナンス</Link>
                </Menu.Item>
              )}
              <Menu.Item
                key="/logout"
                icon={<HomeIcon width={20} height={20} viewBox="0 0 28 28" />}
                onClick={() => {
                  window.localStorage.removeItem("access_token");
                  window.localStorage.removeItem("refresh_token");
                  window.location.reload();
                }}
              >
                Log Out
              </Menu.Item>
              <Menu.Item
                key="/settings"
                icon={
                  <SettingsIcon width={20} height={20} viewBox="0 0 28 28" />
                }
              >
                <Link to="/settings">設定</Link>
              </Menu.Item>
            </Menu>
          )}
          <div className="side-search-container">
            <Input.Search
              placeholder="Search..."
              onPressEnter={(e) => {
                // history.replace(`/?search=${e.target.value}`);
                window.location.href = `/?search=${e.target.value}`;
              }}
            />
          </div>
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            {children}
          </Content>
        </Layout>
        <Modal
          visible={modalState.open}
          onCancel={closeModal}
          footer={[
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>,
            profile.photo && (
              <Button key="remove" type="danger" onClick={removePhoto}>
                Remove Photo
              </Button>
            ),
            <Button key="upload" type="primary">
              <ImgCrop rotate>
                <Upload
                  showUploadList={false}
                  beforeUpload={(file) => {
                    if (file.type.substr(0, 5) !== "image") {
                      Modal.error({
                        title: `画像ファイルをアップロードしてください`,
                      });
                    } else {
                      getBase64(file).then((temp) => {
                        setState((state) => ({
                          ...state,
                          file: file,
                          temp_photo: temp,
                        }));
                      });
                    }
                    return false;
                  }}
                  onChange={(file) => {
                    // console.log("change");
                    // console.log(file);
                  }}
                >
                  <span style={{ color: "#ffffff" }}>Change Photo</span>
                </Upload>
              </ImgCrop>
            </Button>,
            state.file && (
              <Button
                key="submit"
                type="success"
                loading={state.uploadingPhoto}
                onClick={handleUpload}
              >
                送信
              </Button>
            ),
          ]}
        >
          <div className="middle center">
            <Avatar
              size={128}
              src={state.temp_photo === "" ? profile.photo : state.temp_photo}
              style={{ cursor: "pointer" }}
              onClick={() => {
                openModal();
              }}
            />
          </div>
        </Modal>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
